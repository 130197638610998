<template>
  <div class="content">
    <app-cells position="between">
      <app-cells position="start" :indent="false">
        <router-link
          :to="{ name: 'events-manage' }"
          class="btn btn--link"
        >
          <svg width="16" height="15" viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M7.70917 13.7082C7.61629 13.8013 7.50595 13.8752 7.38447 13.9256C7.263 13.976 7.13277 14.002 7.00125 14.002C6.86973 14.002 6.7395 13.976 6.61802 13.9256C6.49655 13.8752 6.38621 13.8013 6.29332 13.7082L0.293962 7.70884C0.200846 7.61595 0.126968 7.50562 0.0765611 7.38414C0.0261537 7.26266 0.000206594 7.13243 0.000206606 7.00091C0.000206617 6.86939 0.0261537 6.73916 0.0765611 6.61769C0.126969 6.49621 0.200846 6.38587 0.293962 6.29299L6.29333 0.293626C6.48108 0.105873 6.73573 0.00039471 7.00125 0.000394734C7.26677 0.000394757 7.52142 0.105873 7.70918 0.293626C7.89693 0.481379 8.00241 0.736028 8.00241 1.00155C8.00241 1.26707 7.89693 1.52172 7.70917 1.70948L2.41574 7.00091L7.70917 12.2923C7.80229 12.3852 7.87617 12.4956 7.92658 12.617C7.97698 12.7385 8.00293 12.8688 8.00293 13.0003C8.00293 13.1318 7.97698 13.262 7.92658 13.3835C7.87617 13.505 7.80229 13.6153 7.70917 13.7082Z" fill="#2B93E7"/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M16 7.00108C16 7.26627 15.8947 7.5206 15.7071 7.70811C15.5196 7.89563 15.2653 8.00098 15.0001 8.00098L2.00149 8.00098C1.7363 8.00098 1.48197 7.89563 1.29445 7.70811C1.10694 7.5206 1.00159 7.26627 1.00159 7.00108C1.00159 6.73589 1.10694 6.48156 1.29445 6.29405C1.48197 6.10653 1.7363 6.00119 2.00149 6.00119L15.0001 6.00119C15.2653 6.00119 15.5196 6.10653 15.7071 6.29405C15.8947 6.48157 16 6.73589 16 7.00108Z" fill="#2B93E7"/>
          </svg>
        </router-link>
        <h1 class="title title--big title--theme">{{ event.name }} (ID: {{ event.id }})</h1>
        <app-switcher
          @change.native="onEventActivityChanged"
          v-model="is_event_active"
          active_text="Активно"
          inactive_text="Неактивно"
        />
      </app-cells>
      <router-link :to="{ name: 'event-edit' }" class="button button--mini">Редактировать мероприятие</router-link>
    </app-cells>
    <div class="event-detail">
      <div class="event-detail__info">
        <h2 class="content__title title">Информация о мероприятии</h2>
        <div class="event-detail__part event-detail__part--flex">
          <div class="event-detail__col">
            <div class="event-detail__row">
              <b>Формат проведения:</b>{{ event.participation_form_name }}
            </div>
            <div class="event-detail__row">
              <b>Тип мероприятия:</b>{{ event.type_name }}
            </div>
          </div>
          <div class="event-detail__col">
            <div class="event-detail__row">
              <b>Дата проведения:</b>{{ event.date_start }} - {{ event.date_end }}
            </div>
            <div class="event-detail__row">
              <b>Организатор:</b>{{ event.organizer }}
            </div>
          </div>
        </div>
        <div class="event-detail__part">
          <div class="event-detail__row">
            <b>Регион проведения:</b>{{ event.region_text }}
          </div>
        </div>
        <h2 class="content__title title">Описание мероприятия</h2>
        <div v-html="event.desc" class="content__paragraph"></div>
      </div>
      <div class="event-detail__img">
        <img v-if="event.crop_url" :src="event.crop_url" alt="Event logo">
        <img v-else src="@/assets/img/events/event-slug.svg" alt="Event slug">
      </div>
    </div>
    <h2 class="content__title title">Контакты</h2>
    <div class="event-detail event-detail--group">
      <div class="event-detail__part" v-for="(contact, index) in event.contacts" :key="index">
        <div class="event-detail__contact">
          <img src="@/assets/img/sidebar/user-icon.svg" alt="Person icon">
          <span>{{ contact.fio }}</span>
        </div>
        <div v-if="contact.phone" class="event-detail__contact">
          <img src="@/assets/img/sidebar/phone-icon.svg" alt="Phone icon">
          <a :href="`tel:${contact.phone}`">{{ contact.phone }}</a>
        </div>
        <div class="event-detail__contact">
          <img src="@/assets/img/sidebar/link-icon.svg" alt="Email icon">
          <a :href="`mailto:${contact.email}`">{{ contact.email }}</a>
        </div>
      </div>
    </div>
    <div class="content__title content__title--party">
      <h2 class="title">Направления</h2>
      <router-link :to="{ name: 'direction-create' }" class="button button--mini">Добавить направление</router-link>
    </div>
    <ShiftList :shifts="event.shifts" :is_view_invite="false" />
  </div>
</template>

<script>
import ShiftList from '@/components/ShiftList'

export default {
  name: 'EventDetail',
  components: {
    ShiftList,
  },
  data() {
    return {
      event: {},
      is_event_active: false
    }
  },
  mounted() {
    this.$store.dispatch('events/GET_DATA', this.$route.params.id)
      .then(response => {
        this.event = response.data
        this.is_event_active = response.data.is_active
        this.$store.commit('events/setEventName', response.data.name)
      })
  },
  methods: {
    onEventActivityChanged() {
      this.$store.dispatch('events/PATCH_DATA', { data: { is_active: this.is_event_active }, id: this.$route.params.id })
        .then(response => {
          this.is_event_active = response.data.is_active
          this.$notify({
            type: 'success',
            title: 'Успех!',
            text: 'Активность мероприятия изменена'
          })
        })
        .catch(error => {
          if (error.response.data.is_active) {
            this.is_event_active = false
            error.response.data.is_active.forEach(item => {
              this.$notify({
                type: 'warn',
                title: 'Внимание!',
                text: item
              })
            })
          }
        })
    }
  }
}
</script>

<style lang="sass">
@import "@/assets/common/index.scss"

.event-detail
  display: flex
  justify-content: space-between
  gap: 30px
  margin-bottom: 30px

  +max-w($laptop_lg)
    flex-direction: column-reverse

.event-detail--group
  display: grid
  grid-template: auto / repeat(auto-fit, minmax(300px, 350px))
  grid-gap: 40px
  justify-content: flex-start

  .event-detail__part
    margin-bottom: 0

.event-detail__part
  margin-bottom: 20px

  &:last-child
    margin-bottom: 0

.event-detail__part--flex
  display: flex

  +max-w($mobile_md)
    flex-direction: column

.event-detail__col
  &:first-child
    margin-right: 60px

    +max-w($desktop_sm)
      margin-right: 30px

    +max-w($mobile_md)
      margin-right: 0
      margin-bottom: 20px

.event-detail__row
  margin-bottom: 20px
  font-size: 16px

  b
    margin-right: 5px
    font-weight: 600

  &:last-child
    margin-bottom: 0

.event-detail__contact
  display: flex
  align-items: center
  margin-bottom: 15px
  font-size: 16px

  img
    margin-right: 10px

  a
    color: $color-text-base
    text-decoration: none
    transition: color 0.3s

    &:hover
      color: $color-theme

  &:last-child
    margin-bottom: 0

.event-detail__img
  align-self: flex-start
  overflow: hidden
  max-width: 600px
  margin: 0 auto
  border-radius: 5px
  line-height: 0

  img
    width: 100%
</style>